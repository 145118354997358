import React, { useEffect, useState } from "react";
import LoginVerify from "../services/api/loginApi"; // Adjust the import path as needed
import { Typography } from "antd";
import Lottie from "react-lottie";
import animatedWelcome from "../assets/welcome.json";

const { Title, Text } = Typography;

export default function Home() {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const user = LoginVerify.getCurrentUser();
    setUserName(user.name || "User");
  }, []);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animatedWelcome,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center mb-8">
        <Title level={2}>Welcome, {userName}!</Title>
        <Text className="text-lg">We're glad to have you here.</Text>
      </div>
      <Lottie
        options={defaultOptions}
        height={400}
        width={"100%"}
        style={{ maxWidth: "650px" }}
      />
      <div className="mt-8 text-center">
        <Text className="text-lg">
          Explore the dashboard to manage your tasks and projects efficiently.
        </Text>
      </div>
    </div>
  );
}
