import React, { useState, useEffect } from "react";
import TextEditor from "../components/blogs/TextEditor";
import { Table, Button, Modal, Input, message, Select } from "antd";
import CategoryApi from "../services/api/categoryApi";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import ServiceApi from "../services/api/serviceApi";
import ServiceTypeApi from "../services/api/serviceTypeApi";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import subServiceApi from "../services/api/subServiceApi";

export default function SubService() {
  const [isAddCategoryModalVisible, setIsAddCategoryModalVisible] =
    useState(false);

  const [title, setTitle] = useState("");
  const [Service, setService] = useState();
  const [description, setDescription] = useState("");
  const [details, setDetails] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [apiService, setApiService] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [serviceFilter, setServiceFilter] = useState();

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Service",
      dataIndex: "serviceId",
      render: (text, record) => record.service?.title || "N/A",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex items-center space-x-2">
          <FaRegEdit
            className="text-xl text-blue-500 cursor-pointer"
            onClick={() => handleEdit(record)}
          />
          <FaEye
            className="text-xl text-green-500 cursor-pointer"
            onClick={() => handleView(record)}
          />
          <MdDelete
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => handleDelete(record)}
          />
        </div>
      ),
    },
  ];

  const handleEdit = (record) => {
    console.log("Edit", record);
    setIsAddCategoryModalVisible(true);
    setTitle(record.title);
    setService(record.service.id);
    setSelectedCategoryId(record.id);
    setIsEdit(true);
  };

  const handleView = (record) => {
    console.log("View", record);
    setIsAddCategoryModalVisible(true);
    setIsView(true);
    setTitle(record.title);
    setDescription(record.description);
    setDetails(record.details);
  };

  const handleDelete = async (record) => {
    console.log("Delete", record);
    const selectedCategoryId = record.id;
    Modal.confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this Sub Service?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        const response = await subServiceApi.delete(selectedCategoryId);
        fetchServiceList();
        fetchSubServiceList();
      },
    });
  };

  const handleAddService = () => {
    setDetails("");
    setIsAddCategoryModalVisible(true);
    console.log("Add Blog");
  };

  const handleSubmit = async () => {
    if (!title) {
      console.error("Error: title is required.");
      message.error("Title is required.");
      return;
    }

    const body = {
      title,
      Service,
    };

    if (isEdit) {
      const response = await subServiceApi.update(selectedCategoryId, body);
      setIsAddCategoryModalVisible(false);
      setTitle("");
      setDescription("");
      setDetails("");
      setSelectedCategoryId("");
      setIsEdit(false);
      fetchServiceList();
      fetchSubServiceList();
      return;
    } else {
      const response = await subServiceApi.create(body);
      setIsAddCategoryModalVisible(false);
      setTitle("");
      setDescription("");
      setDetails("");
      setSelectedCategoryId("");
      fetchServiceList();
      fetchSubServiceList();
    }
  };

  useEffect(() => {
    fetchSubServiceList();
    fetchServiceList();
  }, []);

  const fetchSubServiceList = async () => {
    const response = await subServiceApi.findAll({});
    if (response) {
      console.log("response", response);
      setCategoryList(response);
    }
  };

  const fetchServiceList = async () => {
    const response = await ServiceApi.findAll({});
    if (response) {
      console.log("response", response);
      setApiService(response);
    }
  };
  const handleClearFilter = () => {
    setServiceFilter(undefined);
  };

  const filteredCategoryList = serviceFilter
    ? categoryList.filter((item) => item.serviceId === serviceFilter)
    : categoryList;

  return (
    <div style={{ overflowY: "scroll", maxHeight: "700px" }}>
      <div className=" mx-10">
        <h1 className="text-3xl font-bold mt-4 uppercase">Sub Service</h1>
        <h2 className="text-xs font-medium italic text-gray-400">
          Manage the Sub Service here
        </h2>
        <div className="flex justify-end">
          <Button type="primary" onClick={handleAddService}>
            Add Sub Services
          </Button>
        </div>
        <div className="flex flex-row gap-5">
          <Select
            onChange={(value) => setServiceFilter(value)}
            value={serviceFilter}
            placeholder="Service Filter"
            className="w-2/4"
            disabled={isView}
          >
            {apiService.map((type) => (
              <Select.Option key={type.id} value={type.id}>
                {type.title}
              </Select.Option>
            ))}
          </Select>
          <div className="">
            <Button onClick={handleClearFilter}>Clear Filter</Button>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <Table
            dataSource={filteredCategoryList}
            columns={columns}
            pagination={false}
            // className="custom-table ant-table-thead"

            style={{ width: "100%", margin: "0 auto", marginTop: "20px" }}
          />
        </div>
        <Modal
          title={
            isView
              ? "View Sub Service"
              : isEdit
              ? "Edit Sub Service"
              : "Add Sub Service"
          }
          width={500}
          visible={isAddCategoryModalVisible} // Set the visibility state for the modal
          onCancel={() => {
            setIsAddCategoryModalVisible(false);
            setTitle("");
            setDescription("");
            setSelectedCategoryId("");
            setIsEdit(false);
            setIsView(false);
          }} // Handle the cancel action
          footer={false}
        >
          <div className="flex flex-col space-y-4 p-4">
            <div className="flex flex-row justify-between items-center">
              <label className="w-1/4 text-right pr-2">Services</label>
              <Select
                onChange={(value) => setService(value)}
                value={Service}
                placeholder="Select Service"
                className="w-3/4"
                disabled={isView}
              >
                {apiService.map((type) => (
                  <Select.Option key={type.id} value={type.id}>
                    {type.title}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="flex flex-row justify-between items-center">
              <label className="w-1/4 text-right pr-2">Title</label>
              <Input
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder="Add Title"
                className="w-3/4"
                disabled={isView}
              />
            </div>

            {isView ? null : (
              <div className="flex justify-center items-center">
                <Button type="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
}
