import React, { useState, useEffect } from "react";
import TextEditor from "../components/blogs/TextEditor";
import { Table, Button, Modal, Input, message } from "antd";
import TagApi from "../services/api/tagApi";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

export default function Tag() {
  const [isAddTagModalVisible, setIsAddTagModalVisible] =
    useState(false);
  const [tag, setTag] = useState("");
  const [description, setDescription] = useState("");
  const [tagList, setTagList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [selectedTagId, setSelectedCategoryId] = useState("");

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Tag",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex items-center space-x-2">
          <FaRegEdit
            className="text-xl text-blue-500 cursor-pointer"
            onClick={() => handleEdit(record)}
          />
          <FaEye
            className="text-xl text-green-500 cursor-pointer"
            onClick={() => handleView(record)}
          />
          <MdDelete
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => handleDelete(record)}
          />
        </div>
      ),
    },
  ];

  const handleEdit = (record) => {
    console.log("Edit", record);
    setIsAddTagModalVisible(true);
    setTag(record.name);
    setDescription(record.description);
    setSelectedCategoryId(record.id);
    setIsEdit(true);
  };

  const handleView = (record) => {
    console.log("View", record);
    setIsAddTagModalVisible(true);
    setIsView(true);
    setTag(record.name);
    setDescription(record.description);
  };

  const handleDelete = async (record) => {
    console.log("Delete", record);
    const selectedTagId = record.id;
    Modal.confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this tag?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        const response = await TagApi.delete(selectedTagId);
        fetchTagList();
      },
    });
  };

  const handleAddCategory = () => {
    setIsAddTagModalVisible(true);
    console.log("Add Blog");
  };

  const handleSubmit = async () => {
    if (!tag) {
      console.error("Error: tag is required.");
      message.error("Tag is required.");
      return;
    }
    if (!description) {
      console.error("Error: description is required.");
      message.error("Description is required.");
      return;
    }

    const body = {
      tag,
      description,
    };

    if (isEdit) {
      const response = await TagApi.update(selectedTagId, body);
      setIsAddTagModalVisible(false);
      setTag("");
      setDescription("");
      setSelectedCategoryId("");
      setIsEdit(false);
      return;
    } else {
      const response = await TagApi.create(body);
      setIsAddTagModalVisible(false);
      setTag("");
      setDescription("");
      setSelectedCategoryId("");
      fetchTagList();
    }
  };

  useEffect(() => {
    fetchTagList();
  }, []);

  const fetchTagList = async () => {
    const response = await TagApi.findAll({});
    if (response) {
      console.log("response", response);
      setTagList(response);
    }
  };

  return (
    <div className=" mx-10">
      <h1 className="text-3xl font-bold mt-4 uppercase">
        Tag
      </h1>
      <h2 className="text-xs font-medium italic text-gray-400">
        Manage the Tag here
      </h2>
      <div className="flex justify-end">
        <Button type="primary" onClick={handleAddCategory}>
          Add Tag
        </Button>
      </div>
      <div className="flex justify-center items-center">
        <Table
          dataSource={tagList}
          columns={columns}
          pagination={false}
          // className="custom-table ant-table-thead"
          style={{ width: "100%", margin: "0 auto", marginTop: "20px" }}
        />
      </div>
      <Modal
        title={
          isView ? "View Tag" : isEdit ? "Edit Tag" : "Add Tag"
        }
        width={500}
        visible={isAddTagModalVisible} // Set the visibility state for the modal
        onCancel={() => {
          setIsAddTagModalVisible(false);
          setTag("");
          setDescription("");
        }} // Handle the cancel action
        footer={false}
      >
        <div className="flex flex-col space-y-4 p-4">
          <div className="flex flex-row justify-between items-center">
            <label className="w-1/4 text-right pr-2">Tag</label>
            <Input
              onChange={(e) => setTag(e.target.value)}
              value={tag}
              placeholder="Add Tag"
              className="w-3/4"
              disabled={isView}
            />
          </div>
          <div className="flex flex-row justify-between items-center">
            <label className="w-1/4 text-right pr-2">Description</label>
            <Input
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              placeholder="Add Description"
              className="w-3/4"
              disabled={isView}
            />
          </div>
          {isView ? null : (
            <div className="flex justify-center items-center">
              <Button type="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
