import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";

const App = ({ fetchUploadList }) => {
  const props = {
    name: "file",
    action: `${process.env.REACT_APP_API_BASE_URL}uploadImg/uploadDocument`,
    headers: {
      authorization: "authorization-text",
    },
    multiple: false,
    showUploadList: false,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        fetchUploadList(); // Call fetchUploadList after successful upload
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Upload {...props}>
      <Button type="primary" icon={<UploadOutlined />}>
        Click to Upload
      </Button>
    </Upload>
  );
};

export default App;
