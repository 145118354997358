import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, message, Spin, InputNumber } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import MultiTags from "../components/reuse/MultiTags";
import SeoApi from "../services/api/seoApi";
import SchemaGenerator from "../components/SchemaGenerator/SchemaGenerator";

const { TextArea } = Input;
const { Option } = Select;

export default function AddSeo({ editPage, viewPage }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [metaTags, setMetaTags] = useState([]);
  const [params] = useSearchParams();
  const id = params.get("id");
  const navigate = useNavigate();
  const [schemaModalVisible, setSchemaModalVisible] = useState(false);

  useEffect(() => {
    if (editPage || viewPage) {
      findOneSeo(id);
    }
  }, [editPage, viewPage, id]);

  const findOneSeo = async (id) => {
    try {
      const response = await SeoApi.findOne(id);
      if (response && response[0]) {
        const seoData = response[0];
        form.setFieldsValue({
          metaTitle: seoData.metaTitle,
          metaDescription: seoData.metaDescription,
          permaLinks: seoData.Permalinks,
          metaSchema: seoData.metaSchema,
          allowRobotsIndex: seoData.allowRobotsIndex,
          allowRobotsFollow: seoData.allowRobotsFollow,
          contentEncoding: seoData.contentEncoding,
          revisitAfter: seoData.revisitAfter,
          author: seoData.author,
        });
        setMetaTags(seoData.metaTags || []);
      }
    } catch (error) {
      console.error(error && error.message);
      message.error("Failed to load SEO data");
    }
  };

  const handleMetaTagsChange = (tags) => {
    setMetaTags(tags);
    form.setFieldsValue({ metaTags: tags });
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const seoData = { ...values, metaTags };
      if (editPage) {
        await SeoApi.update(id, seoData);
        navigate("/seo");
        console.log("seo data----->", seoData);
      } else {
        await SeoApi.create(seoData);
        form.resetFields();
        setMetaTags([]);
        console.log("seo data----->", seoData);
      }
    } catch (error) {
      console.error("Error while submitting SEO data", error);
    } finally {
      setLoading(false);
    }
  };
  const handleGenerateSchema = () => {
    setSchemaModalVisible(true);
  };

  const handleSchemaGenerated = (schema) => {
    form.setFieldsValue({ metaSchema: schema });
    setSchemaModalVisible(false);
  };

  return (
    <div style={{ overflowY: "scroll", maxHeight: "100%" }}>
      <div className="flex justify-center items-center min-h-screen bg-gray-100 py-12">
        <div className="bg-white p-10 rounded-lg shadow-md w-full max-w-4xl">
          <h1 className="text-3xl font-bold mb-2 text-center">
            {editPage ? "Edit SEO" : viewPage ? "View SEO" : "Add SEO"}
          </h1>
          <p className="text-sm text-gray-500 mb-6 text-center italic">
            {editPage
              ? "Modify SEO details"
              : viewPage
              ? "SEO information"
              : "Create new SEO entry"}
          </p>

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            disabled={viewPage}
          >
            <Form.Item
              name="metaTitle"
              label="Meta Title"
              rules={[
                { required: true, message: "Please input the meta title!" },
              ]}
            >
              <Input placeholder="Enter the meta title" />
            </Form.Item>

            <Form.Item
              name="metaTags"
              label="Meta Tags"
              rules={[
                {
                  required: true,
                  message: "Please input the meta tags!",
                  validator: (_, value) => {
                    if (metaTags.length > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please input the meta tags!");
                  },
                },
              ]}
            >
              <MultiTags
                setTags={handleMetaTagsChange}
                tags={metaTags}
                tagList={tagList}
                disabled={viewPage}
              />
            </Form.Item>

            <Form.Item
              name="metaDescription"
              label="Meta Description"
              rules={[
                {
                  required: true,
                  message: "Please input the meta description!",
                },
              ]}
            >
              <TextArea rows={4} placeholder="Enter the meta description" />
            </Form.Item>

            <Form.Item
              name="permaLinks"
              label="Permalink URL"
              rules={[
                { required: true, message: "Please input the permalink URL!" },
              ]}
            >
              <Input placeholder="Enter the permalink URL" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                onClick={handleGenerateSchema}
                disabled={viewPage}
              >
                Generate Schema
              </Button>
            </Form.Item>

            <Form.Item
              name="metaSchema"
              label="Schema Markup"
              rules={[
                { required: true, message: "Please input the schema markup!" },
              ]}
            >
              <TextArea rows={6} placeholder="Enter the schema markup" />
            </Form.Item>

            <Form.Item
              name="allowRobotsIndex"
              label="Allow robots to index your website?"
              rules={[{ required: true, message: "Please select an option!" }]}
            >
              <Select placeholder="Select an option">
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="allowRobotsFollow"
              label="Allow robots to follow all links?"
              rules={[{ required: true, message: "Please select an option!" }]}
            >
              <Select placeholder="Select an option">
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="contentEncoding"
              label="What type of content will your site display?"
              rules={[{ required: true, message: "Please select an option!" }]}
            >
              <Select placeholder="Select an option">
                <Option value="UTF-8">UTF-8</Option>
                <Option value="UTF-16">UTF-16</Option>
                <Option value="ISO-8859-1">ISO-8859-1</Option>
                <Option value="Windows-1252">Windows-1252</Option>
              </Select>
            </Form.Item>

            <h3 className="text-lg font-semibold mt-6 mb-4">
              Optional Meta Tags
            </h3>

            <Form.Item
              name="revisitAfter"
              label="Search engines should revisit this page after (days)"
            >
              <InputNumber min={1} placeholder="Enter number of days" />
            </Form.Item>

            <Form.Item name="author" label="Author">
              <Input placeholder="Enter author name" />
            </Form.Item>

            {!viewPage && (
              <Form.Item>
                <Button
                  className="w-full"
                  type="primary"
                  htmlType="submit"
                  disabled={loading}
                >
                  {loading ? <Spin size="small" /> : "Submit"}
                </Button>
              </Form.Item>
            )}
          </Form>
          <SchemaGenerator
            visible={schemaModalVisible}
            onCancel={() => setSchemaModalVisible(false)}
            onGenerate={handleSchemaGenerated}
          />
        </div>
      </div>
    </div>
  );
}
