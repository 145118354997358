import React, { useState, useEffect } from "react";
import {
  Modal,
  Select,
  Form,
  Input,
  Button,
  message,
  InputNumber,
  Space,
  DatePicker,
  Checkbox,
  TimePicker,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

const SchemaGenerator = ({ visible, onCancel, onGenerate }) => {
  const [form] = Form.useForm();
  const [schemaType, setSchemaType] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isRemoteJob, setIsRemoteJob] = useState(false);
  const [hasSalary, setHasSalary] = useState(false);
  const [isOpen24_7, setIsOpen24_7] = useState(false);

  const schemaOptions = [
    { value: "HowTo", label: "How-To" },
    { value: "Article", label: "Article" },
    { value: "JobPosting", label: "Job Posting" },
    { value: "LocalBusiness", label: "Local Business" },
    { value: "Event", label: "Event" },
    { value: "WebSite", label: "Website-siteLinks" },
  ];

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setSchemaType(null);
    }
  }, [visible, form]);

  const renderSchemaInputs = () => {
    switch (schemaType) {
      case "HowTo":
        return (
          <>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              name="totalTime"
              label="Total Time (minutes)"
              rules={[{ required: true }]}
            >
              <InputNumber min={1} placeholder="Enter minutes" />
            </Form.Item>
            <Form.Item
              name="estimatedCost"
              label="Estimated Cost"
              rules={[{ required: true }]}
            >
              <InputNumber min={0} step={0.01} />
            </Form.Item>
            <Form.Item
              name="currency"
              label="Currency"
              rules={[{ required: true }]}
            >
              <Input placeholder="e.g., USD" />
            </Form.Item>
            <Form.Item
              name="image"
              label="Image URL"
              rules={[
                { required: true },
                { type: "url", message: "Please enter a valid URL" },
              ]}
            >
              <Input placeholder="https://example.com/image.jpg" />
            </Form.Item>

            <Form.List name="supply">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Space
                      key={field.key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...field}
                        label={`Supply ${index + 1}`}
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input the supply or delete this field",
                          },
                        ]}
                      >
                        <Input placeholder="Enter a supply" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Supply
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.List name="tool">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Space
                      key={field.key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...field}
                        label={`Tool ${index + 1}`}
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input the tool or delete this field",
                          },
                        ]}
                      >
                        <Input placeholder="Enter a tool" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Tool
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.List name="step">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Space
                      key={field.key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                      direction="vertical"
                    >
                      <Form.Item
                        {...field}
                        label={`Step ${index + 1}`}
                        style={{ marginBottom: 0 }}
                      >
                        <Input.Group compact>
                          <Form.Item
                            name={[field.name, "name"]}
                            noStyle
                            rules={[
                              { required: true, message: "Name is required" },
                            ]}
                          >
                            <Input
                              style={{ width: "30%" }}
                              placeholder="Name"
                            />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "url"]}
                            noStyle
                            rules={[
                              {
                                type: "url",
                                message: "Please enter a valid URL",
                              },
                            ]}
                          >
                            <Input style={{ width: "30%" }} placeholder="URL" />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "image"]}
                            noStyle
                            rules={[
                              {
                                type: "url",
                                message: "Please enter a valid image URL",
                              },
                            ]}
                          >
                            <Input
                              style={{ width: "30%" }}
                              placeholder="Image URL"
                            />
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                      <Form.Item
                        name={[field.name, "instructions"]}
                        rules={[
                          {
                            required: true,
                            message: "Instructions are required",
                          },
                        ]}
                      >
                        <Input.TextArea placeholder="Instructions" />
                      </Form.Item>
                      <Button
                        type="link"
                        onClick={() => remove(field.name)}
                        icon={<MinusCircleOutlined />}
                      >
                        Remove Step
                      </Button>
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Step
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        );
      case "Article":
      case "Article":
        return (
          <>
            <Form.Item
              name="url"
              label="URL"
              rules={[
                { required: true },
                { type: "url", message: "Please enter a valid URL" },
              ]}
            >
              <Input placeholder="https://example.com/article" />
            </Form.Item>
            <Form.Item
              name="headline"
              label="Article Headline"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              name="image"
              label="Image URL"
              rules={[
                { required: true },
                { type: "url", message: "Please enter a valid URL" },
              ]}
            >
              <Input placeholder="https://example.com/image.jpg" />
            </Form.Item>
            <Form.Item
              name="imageWidth"
              label="Image Width"
              rules={[{ required: true }]}
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              name="imageHeight"
              label="Image Height"
              rules={[{ required: true }]}
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              name="authorType"
              label="Author Type"
              rules={[{ required: true }]}
            >
              <Select>
                <Option value="Person">Person</Option>
                <Option value="Organization">Organization</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="authorName"
              label="Author Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="publisherName"
              label="Publisher Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="publisherLogoUrl"
              label="Publisher Logo URL"
              rules={[
                { required: true },
                { type: "url", message: "Please enter a valid URL" },
              ]}
            >
              <Input placeholder="https://example.com/publisher-logo.png" />
            </Form.Item>
            <Form.Item
              name="publisherLogoWidth"
              label="Publisher Logo Width"
              rules={[{ required: true }]}
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              name="publisherLogoHeight"
              label="Publisher Logo Height"
              rules={[{ required: true }]}
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              name="datePublished"
              label="Date Published"
              rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="dateModified"
              label="Date Modified"
              rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>
          </>
        );
      case "JobPosting":
        return (
          <>
            <Form.Item
              name="title"
              label="Job Title"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="identifier"
              label="Identifier"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Job Description"
              rules={[{ required: true }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="hiringOrganization"
              label="Company"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="hiringOrganizationUrl"
              label="Company URL"
              rules={[
                { required: true },
                { type: "url", message: "Please enter a valid URL" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="industry"
              label="Industry"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="employmentType"
              label="Employment Type"
              rules={[{ required: true }]}
            >
              <Select mode="multiple">
                <Option value="FULL_TIME">Full time</Option>
                <Option value="PART_TIME">Part time</Option>
                <Option value="CONTRACTOR">Contractor</Option>
                <Option value="TEMPORARY">Temporary</Option>
                <Option value="INTERN">Intern</Option>
                <Option value="VOLUNTEER">Volunteer</Option>
                <Option value="PER_DIEM">Per diem</Option>
                <Option value="OTHER">Other</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="workHours"
              label="Work Hours"
              rules={[{ required: true }]}
            >
              <Input placeholder="e.g. 8am-5pm, shift" />
            </Form.Item>
            <Form.Item
              name="datePosted"
              label="Date Posted"
              rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="validThrough"
              label="Valid Through"
              rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="countryCode"
              label="Country Code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="regionCode"
              label="Region Code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="isRemote" valuePropName="checked">
              <Checkbox onChange={(e) => setIsRemoteJob(e.target.checked)}>
                Remote Job
              </Checkbox>
            </Form.Item>
            {!isRemoteJob && (
              <>
                <Form.Item
                  name="streetAddress"
                  label="Street"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="addressLocality"
                  label="City"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="postalCode"
                  label="Zip Code"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
            <Form.Item
              name="baseSalary"
              label="Salary (or min. salary)"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                step={1000}
                onChange={(value) => setHasSalary(!!value)}
              />
            </Form.Item>

            {hasSalary && (
              <>
                <Form.Item name="maxSalary" label="Max. Salary">
                  <InputNumber min={0} step={1000} />
                </Form.Item>
                <Form.Item
                  name="salaryCurrency"
                  label="Currency Code"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="e.g., USD" />
                </Form.Item>
                <Form.Item
                  name="salaryUnit"
                  label="Payment Unit"
                  rules={[{ required: true }]}
                >
                  <Select>
                    <Option value="YEAR">Year</Option>
                    <Option value="MONTH">Month</Option>
                    <Option value="WEEK">Week</Option>
                    <Option value="HOUR">Hour</Option>
                  </Select>
                </Form.Item>
              </>
            )}

            <Form.Item
              name="responsibilities"
              label="Responsibilities"
              rules={[{ required: true }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="skills"
              label="Skills"
              rules={[{ required: true }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="qualifications"
              label="Qualifications"
              rules={[{ required: true }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="educationRequirements"
              label="Education Requirements"
              rules={[{ required: true }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="experienceRequirements"
              label="Experience Requirements"
              rules={[{ required: true }]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </>
        );
      case "LocalBusiness":
        return (
          <>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="image"
              label="Image URL"
              rules={[
                { required: true },
                { type: "url", message: "Please enter a valid URL" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="id"
              label="Id (URL)"
              rules={[
                { required: true },
                { type: "url", message: "Please enter a valid URL" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="url"
              label="URL"
              rules={[
                { required: true },
                { type: "url", message: "Please enter a valid URL" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="telephone"
              label="Phone"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="priceRange"
              label="Price Range"
              rules={[{ required: true }]}
            >
              <Input placeholder="e.g. $$$" />
            </Form.Item>
            <Form.Item
              name="streetAddress"
              label="Street"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="addressLocality"
              label="City"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="postalCode"
              label="Zip Code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="addressCountry"
              label="Country Code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="addressRegion"
              label="Region Code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.List name="sameAs">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Space
                      key={field.key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...field}
                        label={`Social Profile ${index + 1}`}
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input the social profile URL or delete this field",
                          },
                          { type: "url", message: "Please enter a valid URL" },
                        ]}
                      >
                        <Input placeholder="https://example.com/profile" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Social Profile
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item name="openAllDay" valuePropName="checked">
              <Checkbox onChange={(e) => setIsOpen24_7(e.target.checked)}>
                Open 24/7
              </Checkbox>
            </Form.Item>

            {!isOpen24_7 && (
              <Form.List name="openingHours">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Space
                        key={field.key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, "opens"]}
                          label="Opens At"
                          rules={[
                            {
                              required: true,
                              message: "Please input opening time",
                            },
                          ]}
                        >
                          <TimePicker format="HH:mm" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "closes"]}
                          label="Closes At"
                          rules={[
                            {
                              required: true,
                              message: "Please input closing time",
                            },
                          ]}
                        >
                          <TimePicker format="HH:mm" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "dayOfWeek"]}
                          label="Days"
                          rules={[
                            {
                              required: true,
                              message: "Please select at least one day",
                            },
                          ]}
                        >
                          <Checkbox.Group
                            options={[
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                              "Sunday",
                            ]}
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Opening Hours
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )}
          </>
        );
      case "Event":
        return (
          <>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="image"
              label="Image URL"
              rules={[
                { required: true },
                { type: "url", message: "Please enter a valid URL" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="startDate"
              label="Start Date"
              rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="startTime"
              label="Start Time"
              rules={[{ required: true }]}
            >
              <TimePicker format="HH:mm" />
            </Form.Item>
            <Form.Item
              name="endDate"
              label="End Date"
              rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="endTime"
              label="End Time"
              rules={[{ required: true }]}
            >
              <TimePicker format="HH:mm" />
            </Form.Item>
            <Form.Item
              name="performerType"
              label="Performer Type"
              rules={[{ required: true }]}
            >
              <Select>
                <Option value="Person">Person</Option>
                <Option value="PerformingGroup">Performing Group</Option>
                <Option value="MusicGroup">Music Group</Option>
                <Option value="DanceGroup">Dance Group</Option>
                <Option value="TheaterGroup">Theater Group</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="venueName"
              label="Venue Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="streetAddress"
              label="Street"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="addressLocality"
              label="City"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="postalCode"
              label="Zip Code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="addressCountry"
              label="Country Code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="addressRegion"
              label="Region Code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="currencyCode"
              label="Currency Code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.List name="tickets">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Space
                      key={field.key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...field}
                        name={[field.name, "name"]}
                        fieldKey={[field.fieldKey, "name"]}
                        rules={[
                          { required: true, message: "Missing ticket name" },
                        ]}
                      >
                        <Input placeholder="Ticket Name" />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, "price"]}
                        fieldKey={[field.fieldKey, "price"]}
                        rules={[{ required: true, message: "Missing price" }]}
                      >
                        <InputNumber min={0} placeholder="Price" />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, "url"]}
                        fieldKey={[field.fieldKey, "url"]}
                        rules={[
                          { required: true, message: "Missing URL" },
                          { type: "url", message: "Invalid URL" },
                        ]}
                      >
                        <Input placeholder="URL" />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, "availability"]}
                        fieldKey={[field.fieldKey, "availability"]}
                        rules={[
                          { required: true, message: "Missing availability" },
                        ]}
                      >
                        <Select placeholder="Availability">
                          <Option value="InStock">In Stock</Option>
                          <Option value="SoldOut">Sold Out</Option>
                          <Option value="PreOrder">Pre Order</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, "availableFrom"]}
                        fieldKey={[field.fieldKey, "availableFrom"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing available from date",
                          },
                        ]}
                      >
                        <DatePicker placeholder="Available From" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Ticket
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        );
      case "WebSite":
        return (
          <>
            <Form.Item
              name="websitename"
              label="Website Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="URL"
              label="URL"
              rules={[
                { required: true },
                { type: "url", message: "Please enter a valid URL" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="searchurl"
              label="Search URL"
              rules={[
                { required: true },
                { type: "url", message: "Please enter a valid URL" },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        );
      default:
        return null;
    }
  };

  const handleSchemaTypeChange = (value) => {
    setSchemaType(value);
    form.resetFields();
    form.setFieldsValue({ schemaType: value });
  };

  const handleGenerate = async () => {
    try {
      setIsGenerating(true);
      const values = await form.validateFields();
      console.log("Form values:", values); // Debug log

      let schema = {
        "@context": "https://schema.org",
        "@type": values.schemaType,
        ...values,
      };
      if (values.schemaType === "HowTo") {
        schema.totalTime = `PT${values.totalTime}M`;
        schema.estimatedCost = {
          "@type": "MonetaryAmount",
          value: values.estimatedCost,
          currency: values.currency,
        };
        if (values.supply) {
          schema.supply = values.supply.map((item) => ({
            "@type": "HowToSupply",
            name: item,
          }));
        }
        if (values.tool) {
          schema.tool = values.tool.map((item) => ({
            "@type": "HowToTool",
            name: item,
          }));
        }
        if (values.step) {
          schema.step = values.step.map((item, index) => ({
            "@type": "HowToStep",
            name: item.name,
            url: item.url,
            image: item.image,
            text: item.instructions,
          }));
        }
      } else if (values.schemaType === "Article") {
        schema = {
          "@context": "https://schema.org",
          "@type": "Article",
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": values.url,
          },
          headline: values.headline,
          description: values.description,
          image: {
            "@type": "ImageObject",
            url: values.image,
            width: values.imageWidth,
            height: values.imageHeight,
          },
          author: {
            "@type": values.authorType,
            name: values.authorName,
          },
          publisher: {
            "@type": "Organization",
            name: values.publisherName,
            logo: {
              "@type": "ImageObject",
              url: values.publisherLogoUrl,
              width: values.publisherLogoWidth,
              height: values.publisherLogoHeight,
            },
          },
          datePublished: values.datePublished.toISOString(),
          dateModified: values.dateModified.toISOString(),
        };
      } else if (values.schemaType === "JobPosting") {
        schema = {
          "@context": "https://schema.org",
          "@type": "JobPosting",
          title: values.title,
          description: values.description,
          identifier: {
            "@type": "PropertyValue",
            name: "Job ID",
            value: values.identifier,
          },
          datePosted: values.datePosted.toISOString(),
          validThrough: values.validThrough.toISOString(),
          employmentType: values.employmentType[0],
          hiringOrganization: {
            "@type": "Organization",
            name: values.hiringOrganization,
            sameAs: values.hiringOrganizationUrl,
          },
          jobLocation: values.isRemote
            ? {
                "@type": "VirtualLocation",
              }
            : {
                "@type": "Place",
                address: {
                  "@type": "PostalAddress",
                  streetAddress: values.streetAddress,
                  addressLocality: values.addressLocality,
                  postalCode: values.postalCode,
                  addressCountry: values.countryCode,
                  addressRegion: values.regionCode,
                },
              },
          baseSalary: {
            "@type": "MonetaryAmount",
            currency: values.salaryCurrency,
            value: {
              "@type": "QuantitativeValue",
              minValue: values.baseSalary,
              maxValue: values.maxSalary || values.baseSalary,
              unitText: values.salaryUnit,
            },
          },
          industry: values.industry,
          workHours: values.workHours,
          responsibilities: values.responsibilities,
          skills: values.skills,
          qualifications: values.qualifications,
          educationRequirements: values.educationRequirements,
          experienceRequirements: values.experienceRequirements,
        };
        if (values.isRemote) {
          schema.jobLocationType = "TELECOMMUTE";
        }
      } else if (values.schemaType === "LocalBusiness") {
        schema = {
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "@id": values.id,
          name: values.name,
          image: values.image,
          url: values.url,
          telephone: values.telephone,
          priceRange: values.priceRange,
          address: {
            "@type": "PostalAddress",
            streetAddress: values.streetAddress,
            addressLocality: values.addressLocality,
            postalCode: values.postalCode,
            addressCountry: values.addressCountry,
            addressRegion: values.addressRegion,
          },
          sameAs: values.sameAs,
        };

        if (values.openAllDay) {
          schema.openingHours = "Mo-Su";
        } else if (values.openingHours && values.openingHours.length > 0) {
          schema.openingHoursSpecification = values.openingHours.map(
            (hours) => ({
              "@type": "OpeningHoursSpecification",
              opens: hours.opens.format("HH:mm"),
              closes: hours.closes.format("HH:mm"),
              dayOfWeek: hours.dayOfWeek,
            })
          );
        }
      } else if (values.schemaType === "WebSite") {
        schema = {
          "@context": "https://schema.org",
          "@type": "Website",
          name: values.websitename,
          url: values.URL,
          potentialAction: {
            "@type": "SearchAction",
            target: `${values.searchurl}{search_term_string}`,
            "query-input": "required name=search_term_string",
          },
        };
      }
      if (values.schemaType === "Event") {
        schema = {
          "@context": "https://schema.org",
          "@type": "Event",
          name: values.name,
          description: values.description,
          image: values.image,
          startDate: `${values.startDate.format(
            "YYYY-MM-DD"
          )}T${values.startTime.format("HH:mm")}`,
          endDate: `${values.endDate.format(
            "YYYY-MM-DD"
          )}T${values.endTime.format("HH:mm")}`,
          performer: {
            "@type": values.performerType,
          },
          location: {
            "@type": "Place",
            name: values.venueName,
            address: {
              "@type": "PostalAddress",
              streetAddress: values.streetAddress,
              addressLocality: values.addressLocality,
              postalCode: values.postalCode,
              addressCountry: values.addressCountry,
              addressRegion: values.addressRegion,
            },
          },
          offers: values.tickets.map((ticket) => ({
            "@type": "Offer",
            name: ticket.name,
            price: ticket.price,
            priceCurrency: values.currencyCode,
            url: ticket.url,
            availability: `https://schema.org/${ticket.availability}`,
            validFrom: ticket.availableFrom.format("YYYY-MM-DD"),
          })),
        };
      }

      delete schema.schemaType;
      console.log("Generated schema:", schema); // Debug log

      onGenerate(JSON.stringify(schema, null, 2));
      message.success("Schema generated successfully");
      onCancel(); // Close the modal after successful generation
    } catch (error) {
      console.error("Error generating schema:", error);
      message.error(
        "Failed to generate schema. Please check your inputs and try again."
      );
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Modal
      className="my-4"
      visible={visible}
      title="Generate Schema"
      onCancel={onCancel}
      centered
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="generate"
          type="primary"
          onClick={handleGenerate}
          loading={isGenerating}
        >
          Generate
        </Button>,
      ]}
      width={800}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="schemaType"
          label="Which Schema would you like to create?"
          rules={[{ required: true, message: "Please select a schema type" }]}
        >
          <Select onChange={handleSchemaTypeChange}>
            {schemaOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {renderSchemaInputs()}
      </Form>
    </Modal>
  );
};

export default SchemaGenerator;
