import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  HomeOutlined,
  GlobalOutlined,
  BookOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  DownOutlined,
  RightOutlined,
  UpOutlined,
  AreaChartOutlined,
  BankOutlined,
  UsergroupAddOutlined,
  DisconnectOutlined
} from "@ant-design/icons";
import logo from "../../assets/navbar/logo.svg";
import arrow_right from "../../assets/navbar/arrow_right.svg";
import arrow_left from "../../assets/navbar/arrow_left.svg";
import avatar from "../../assets/navbar/sample_avatar.png";
import Logo from "../../assets/Egniol_web_Logo_White.png";
import Logo2 from "../../assets/Favicon.png";
import LoginVerify from "../../services/api/loginApi";

export default function Navbar({ isOpen, setIsOpen, children }) {
  const path = useLocation().pathname;

  const [userDetail, setUserDetail] = useState({});
  const [expandedSection, setExpandedSection] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [navContent, setNavContent] = useState([]);

  useEffect(() => {
    const user = {
      username: localStorage.getItem("nameBlog"),
      email: localStorage.getItem("emailBlog"),
    };
    const userRolee = LoginVerify?.getCurrentUser();
    setUserRole(userRolee?.role);
    setUserDetail(user);
    setNavContent(getNavContent(userRolee?.role));
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const scrollBarCSS = `
    #scrollableNav::-webkit-scrollbar {
      width: 5px;
    }
    #scrollableNav::-webkit-scrollbar-thumb {
      background: #2E90FA;
      border-radius: 6px;
    }
    #labelSearch {
      position: relative;
    }
    #labelSearch:before {
      content: "";
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      width: 16px;
      background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 16L12.375 12.375M14.3333 7.66667C14.3333 11.3486 11.3486 14.3333 7.66667 14.3333C3.98477 14.3333 1 11.3486 1 7.66667C1 3.98477 3.98477 1 7.66667 1C11.3486 1 14.3333 3.98477 14.3333 7.66667Z' stroke='gray' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") center / contain no-repeat;
    }
  `;

  const getNavContent = (role) => {
    const allContent = [
      {
        icon: <HomeOutlined />,
        title: "Home",
        link: "/home",
      },
      {
        icon: <GlobalOutlined />,
        title: "Website",
        children: [
          { title: "SEO", link: "/seo" },
          { title: "Service Types", link: "/servicetype" },
          { title: "Services", link: "/services" },
          { title: "Sub Services", link: "/subservice" },
        ],
      },
      {
        icon: <BookOutlined />,
        title: "Blogs",
        children: [
          { title: "Blogs", link: "/blogs" },
          { title: "Tags", link: "/tag" },
          { title: "Category", link: "/category" },
          { title: "Images", link: "/images" },
        ],
      },
      {
        icon: <UserOutlined />,
        title: "Leads",
        children: [
          { title: "FormFillup", link: "/complaint" },
          { title: "Leads", link: "/leads" },
        ],
      },
      {
        icon: <BankOutlined />,
        title: "Msme",
        link: "/msme",
      },
      {
        icon: <UsergroupAddOutlined />,
        title: "Add Users",
        link: "/addUser",
      },
      {
        icon: <DisconnectOutlined />,
        title: "Url",
        link: "/url-info",
      },
    ];

    switch (role) {
      case "admin":
        return allContent;
      case "seo_manager":
        return [
          allContent[0],
          {
            ...allContent[1],
            children: [allContent[1].children[0]],
          },
          allContent[2],
        ];
      case "blogger":
        return [allContent[0], allContent[2]];
      case "leads_manager":
        return [allContent[0], allContent[3]];
      default:
        return [];
    }
  };

  useEffect(() => {
    path !== "/" ? setIsOpen(true) : setIsOpen(false);
    // eslint-disable-next-line
  }, [path]);

  const handleSetIsOpen = () => {
    setIsOpen(!isOpen);
  };
  const LinkActive = (link) => {
    if (!link) return false;
    const linkCase = link.toLowerCase();
    const pathCase = path.toLowerCase();
    return pathCase.includes(linkCase);
  };

  const DropdownItem = ({ item, isOpen }) => {
    const isExpanded = expandedSection === item.title;

    return (
      <div>
        {item.children ? (
          <button
            onClick={() => setExpandedSection(isExpanded ? null : item.title)}
            className={`flex items-center gap-3 px-2 py-2 rounded-md w-full ${
              LinkActive(item.link) && "bg-[#202020]"
            }`}
          >
            {item.icon}
            {isOpen && (
              <div className="flex justify-between w-full pr-4">
                <span>{item.title}</span>
                {isExpanded ? <UpOutlined /> : <DownOutlined />}
              </div>
            )}
          </button>
        ) : (
          <Link
            to={item.link}
            className={`flex items-center gap-3 px-2 py-2 rounded-md ${
              LinkActive(item.link) && "bg-[#202020]"
            }`}
          >
            {item.icon}
            {isOpen && <span>{item.title}</span>}
          </Link>
        )}
        {isOpen && isExpanded && item.children && (
          <div className="ml-6">
            {item.children.map((child, index) => (
              <Link
                key={index}
                to={child.link}
                className={`flex items-center gap-3 px-2 py-2 rounded-md ${
                  LinkActive(child.link) && "bg-[#202020]"
                }`}
              >
                {child.title}
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  };

  console.log("userrrrr", userRole);

  return (
    <>
      <style>{scrollBarCSS}</style>
      <div className="flex w-full h-screen">
        <div
          className={`flex flex-col justify-between h-full bg-[#233240] text-white ${
            !isOpen ? "items-center w-[60px] lg:w-[80px]" : "w-[250px]"
          }`}
        >
          <div className="w-full">
            <div
              className={`h-[8vh] flex items-center justify-center gap-4 py-8 bg-[#233240] ${
                !isOpen && "justify-center"
              }`}
            >
              {isOpen ? (
                <div className="flex items-center gap-2">
                  <Link to="/home">
                    <img src={Logo} alt="Logo" className="h-10" />
                  </Link>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  <Link to="/home">
                    <img src={Logo2} alt="Logo" className="h-10" />
                  </Link>
                </div>
              )}
            </div>
            <div
              id="scrollableNav"
              className={`h-[70vh] flex flex-col gap-[10px] px-2 pt-2 overflow-y-auto ${
                !isOpen && "items-center"
              }`}
            >
              <div
                className={`flex flex-col gap-3 w-full ${
                  isOpen ? "items-end" : "items-center"
                }`}
              >
                <button
                  onClick={handleSetIsOpen}
                  className={`flex items-center w-fit px-1 py-1 rounded-md`}
                >
                  {isOpen ? (
                    <img src={arrow_left} alt="Collapse" />
                  ) : (
                    <img src={arrow_right} alt="Expand" />
                  )}
                </button>
              </div>
              {navContent.map((item, index) => (
                <DropdownItem key={index} item={item} isOpen={isOpen} />
              ))}
              <div
                className={`md:hidden flex flex-col h-full justify-end ${
                  !isOpen ? "items-center gap-3" : "gap-2"
                }`}
              >
                <div className="flex justify-center gap-2">
                  <Link to="">
                    <img src={avatar} alt="Avatar" className={`h-10`} />
                  </Link>
                  {isOpen && (
                    <div className="flex gap-1">
                      <div className="flex flex-col justify-center text-sm">
                        <p className="truncate w-36">{userDetail?.username}</p>
                        <p className="truncate w-36">{userDetail?.email}</p>
                      </div>
                      <button
                        onClick={handleLogout}
                        className="flex justify-start h-fit w-[35px]"
                      >
                        <LogoutOutlined className="-mt-2" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`h-[22vh] hidden md:flex flex-col justify-end pb-4 px-2 ${
              !isOpen ? "items-center gap-3" : "gap-2"
            }`}
          >
            <div className="flex justify-center gap-2">
              <Link to="">
                <img src={avatar} alt="Avatar" className={`h-10`} />
              </Link>
              {isOpen && (
                <div className="flex gap-1">
                  <div className="flex flex-col justify-center text-sm">
                    <p className="truncate w-36">{userDetail?.username}</p>
                    <p className="truncate w-36">{userDetail?.email}</p>
                  </div>
                  <button
                    onClick={handleLogout}
                    className="flex justify-start h-fit w-[35px]"
                  >
                    <LogoutOutlined className="-mt-2" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`${
            isOpen
              ? "w-[calc(100%-250px)]"
              : "w-[calc(100%-60px)] lg:w-[calc(100%-80px)]"
          }`}
        >
          {children}
        </div>
      </div>
    </>
  );
}
