import React, { useState, useEffect } from "react";
import TextEditor from "../components/blogs/TextEditor";
import { Table, Button, Modal, Input, message } from "antd";
import CategoryApi from "../services/api/categoryApi";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

export default function Category() {
  const [isAddCategoryModalVisible, setIsAddCategoryModalVisible] =
    useState(false);
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Category",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex items-center space-x-2">
          <FaRegEdit
            className="text-xl text-blue-500 cursor-pointer"
            onClick={() => handleEdit(record)}
          />
          <FaEye
            className="text-xl text-green-500 cursor-pointer"
            onClick={() => handleView(record)}
          />
          <MdDelete
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => handleDelete(record)}
          />
        </div>
      ),
    },
  ];

  const handleEdit = (record) => {
    console.log("Edit", record);
    setIsAddCategoryModalVisible(true);
    setCategory(record.name);
    setDescription(record.description);
    setSelectedCategoryId(record.id);
    setIsEdit(true);
  };

  const handleView = (record) => {
    console.log("View", record);
    setIsAddCategoryModalVisible(true);
    setIsView(true);
    setCategory(record.name);
    setDescription(record.description);
  };

  const handleDelete = async (record) => {
    console.log("Delete", record);
    const selectedCategoryId = record.id;
    Modal.confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this category?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        const response = await CategoryApi.delete(selectedCategoryId);
        fetchCategoryList();
      },
    });
  };

  const handleAddCategory = () => {
    setIsAddCategoryModalVisible(true);
    console.log("Add Blog");
  };

  const handleSubmit = async () => {
    if (!category) {
      console.error("Error: category is required.");
      message.error("Category is required.");
      return;
    }
    if (!description) {
      console.error("Error: description is required.");
      message.error("Description is required.");
      return;
    }

    const body = {
      category,
      description,
    };

    if (isEdit) {
      const response = await CategoryApi.update(selectedCategoryId, body);
      setIsAddCategoryModalVisible(false);
      setCategory("");
      setDescription("");
      setSelectedCategoryId("");
      setIsEdit(false);
      return;
    } else {
      const response = await CategoryApi.create(body);
      setIsAddCategoryModalVisible(false);
      setCategory("");
      setDescription("");
      setSelectedCategoryId("");
      fetchCategoryList();
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const fetchCategoryList = async () => {
    const response = await CategoryApi.findAll({});
    if (response) {
      console.log("response", response);
      setCategoryList(response);
    }
  };

  return (
    <div className=" mx-10">
      <h1 className="text-3xl font-bold mt-4 uppercase">Category</h1>
      <h2 className="text-xs font-medium italic text-gray-400">
        Manage the Category here
      </h2>
      <div className="flex justify-end">
        <Button type="primary" onClick={handleAddCategory}>
          Add Category
        </Button>
      </div>
      <div className="flex justify-center items-center">
        <Table
          dataSource={categoryList}
          columns={columns}
          pagination={false}
          // className="custom-table ant-table-thead"
          style={{ width: "100%", margin: "0 auto", marginTop: "20px" }}
        />
      </div>
      <Modal
        title={
          isView ? "View Category" : isEdit ? "Edit Category" : "Add Category"
        }
        width={500}
        visible={isAddCategoryModalVisible} // Set the visibility state for the modal
        onCancel={() => {
          setIsAddCategoryModalVisible(false);
          setCategory("");
          setDescription("");
          setSelectedCategoryId("");
          setIsEdit(false);
          setIsView(false);
        }} // Handle the cancel action
        footer={false}
      >
        <div className="flex flex-col space-y-4 p-4">
          <div className="flex flex-row justify-between items-center">
            <label className="w-1/4 text-right pr-2">Category</label>
            <Input
              onChange={(e) => setCategory(e.target.value)}
              value={category}
              placeholder="Add Category"
              className="w-3/4"
              disabled={isView}
            />
          </div>
          <div className="flex flex-row justify-between items-center">
            <label className="w-1/4 text-right pr-2">Description</label>
            <Input
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              placeholder="Add Description"
              className="w-3/4"
              disabled={isView}
            />
          </div>
          {isView ? null : (
            <div className="flex justify-center items-center">
              <Button type="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
