import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Input, message } from "antd";
import UrlApi from "../services/api/urlApi";

export default function UrlInfo() {
  const [urlList, setUrlList] = useState([]);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "srNo",
      key: "srNo",
      width: 50, // Set the width for this column
    },
    {
      title: "Url",
      dataIndex: "name",
      key: "name",
      width: 300, // Set the width for this column
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      width: 50, // Set the width for this column
    },
  ];

  useEffect(() => {
    fetchUrlList();
  }, []);

  const fetchUrlList = async () => {
    const response = await UrlApi.findAll({});
    if (response) {
      console.log("response", response);
      setUrlList(response);
    }
  };

  return (
    <div className=" mx-10">
      <h1 className="text-3xl font-bold mt-4 uppercase">Url Info</h1>
      <h2 className="text-xs font-medium italic text-gray-400">
        View the redirecting url here
      </h2>
      <div className="flex justify-center items-center">
        <Table
          dataSource={urlList}
          columns={columns}
          // pagination={false}
          pagination={{ pageSize: 10 }}
          scroll={{ y: 500 }} // Add this line to make the table scrollable vertically
          style={{ width: "100%", margin: "0 auto", marginTop: "20px" }}
        />
      </div>
    </div>
  );
}
