import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import NavbarLayout from "./components/layouts/NavbarLayout";
import BlankLayout from "./components/layouts/BlankLayout";
import Blogs from "./pages/Blogs";
import Category from "./pages/Category";
import Tag from "./pages/Tag";
import UploadImg from "./pages/UploadImg";
// import AddBlog from "./pages/AddBlog";
import Services from "./pages/Services";
import ServiceType from "./pages/ServiceType";
import SubService from "./pages/SubService";
import Complaint from "./pages/Complaint";
import Leads from "./pages/Leads";
import Seo from "./pages/Seo";
import AddSeo from "./pages/AddSeo";
import Msme from "./pages/Msme";
import MsmeDetails from "./pages/MsmeDetails";
import ProtectedRoute from "./components/ProtectedRoute";
import Unauthorized from "./pages/Unauthorized";
import AddUser from "./pages/AddUser";
import UrlInfo from "./pages/UrlInfo";

function App() {
  return (
    <Routes>
      <Route path="/" element={<NavbarLayout />}>
        <Route
          element={
            <ProtectedRoute
              allowedRoles={[
                "admin",
                "seo_manager",
                "blogger",
                "leads_manager",
              ]}
            />
          }
        >
          <Route path="/home" element={<Home />} />
        </Route>

        <Route
          element={
            <ProtectedRoute
              allowedRoles={["admin", "blogger", "seo_manager"]}
            />
          }
        >
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/category" element={<Category />} />
          <Route path="/tag" element={<Tag />} />
          <Route path="/images" element={<UploadImg />} />
        </Route>

        <Route
          element={<ProtectedRoute allowedRoles={["admin", "seo_manager"]} />}
        >
          <Route path="/seo" element={<Seo />} />
          <Route path="/add-seo" element={<AddSeo />} />
          <Route path="/seo/edit-seo" element={<AddSeo editPage={true} />} />
          <Route path="/seo/view-seo" element={<AddSeo viewPage={true} />} />
        </Route>

        <Route
          element={<ProtectedRoute allowedRoles={["admin", "leads_manager"]} />}
        >
          <Route path="/leads" element={<Leads />} />
          <Route path="/complaint" element={<Complaint />} />
        </Route>

        <Route element={<ProtectedRoute allowedRoles={["admin"]} />}>
          <Route path="/services" element={<Services />} />
          <Route path="/servicetype" element={<ServiceType />} />
          <Route path="/subservice" element={<SubService />} />
          <Route path="/complaint" element={<Complaint />} />
          <Route path="/msme" element={<Msme />} />
          <Route path="/msme-details" element={<MsmeDetails />} />
          <Route path="/addUser" element={<AddUser />} />
          <Route path="/url-info" element={<UrlInfo />} />
        </Route>
      </Route>

      <Route path="/" element={<BlankLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Route>
    </Routes>
  );
}

export default App;
