import React from "react";
import { Card, Row, Col, Statistic } from "antd";
import {
  UserOutlined,
  CalendarOutlined,
  RiseOutlined,
  DashboardOutlined,
} from "@ant-design/icons";

const LeadStatsCard = ({ total, today, weekly, monthly }) => {
  return (
    <Card title="Lead Statistics" className="mb-6">
      <Row gutter={16}>
        <Col xs={24} sm={12} md={6}>
          <Statistic
            title="Total Leads"
            value={total}
            prefix={<UserOutlined />}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Statistic
            title="Today's Leads"
            value={today}
            prefix={<CalendarOutlined />}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Statistic
            title="Weekly Leads"
            value={weekly}
            prefix={<RiseOutlined />}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Statistic
            title="Monthly Leads"
            value={monthly}
            prefix={<DashboardOutlined />}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default LeadStatsCard;
