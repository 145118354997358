import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import LoginVerify from "../services/api/loginApi";

const Unauthorized = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/home");
  };

  const handleLogout = () => {
    LoginVerify.logout();
    navigate("/login");
  };

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={[
        <Button type="primary" key="home" onClick={handleGoHome}>
          Go Home
        </Button>,
        <Button key="logout" onClick={handleLogout}>
          Log Out
        </Button>,
      ]}
    />
  );
};

export default Unauthorized;
