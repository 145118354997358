import React, { useState } from "react";
import { Form, Input, Button, DatePicker, Spin, message } from "antd";
import ContactApi from "../services/api/contactApi";

const Complaint = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    const formattedValues = {
      ...values,
      date: values.date ? values.date.format("DD-MM-YYYY") : null,
    };

    try {
      setLoading(true);
      const response = await ContactApi.create(formattedValues);
      if (response) {
        message.success("Form submitted successfully!");
      }
    } catch (error) {
      console.error("Error while sending contact", error);
      message.error("Failed to submit form. Please try again.");
    } finally {
      setLoading(false);
      form.resetFields();
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Complaint Form</h2>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="mobileNo"
            label="Mobile No"
            rules={[
              { required: true, message: "Please input your mobile number!" },
              {
                pattern: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit mobile number!",
              },
            ]}
          >
            <Input maxLength={10} />
          </Form.Item>

          <Form.Item
            name="company"
            label="Company"
            rules={[{ required: true, message: "Please input the company!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="date"
            label="Date"
            rules={[{ required: true, message: "Please select the date!" }]}
          >
            <DatePicker className="w-full" format="DD-MM-YYYY" />
          </Form.Item>

          <Form.Item>
            <Button
              className={`w-full ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
              type="primary"
              htmlType="submit"
            >
              {loading ? <Spin size="small" /> : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Complaint;
