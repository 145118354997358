import { message } from "antd";
import axios from "axios";

const MsmeApi = {
  findAll: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}msme/findAll`,
        { params }
      );

      if (response.data.status) {
        console.log("response Msme ---->", response);
        return response.data;
      } else {
        console.log("response", response.data.message);
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },
  findAllCsv: async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}msme/findAllCsv`
      );
      if (response.data.status) {
        // console.log("response", response);
        return response.data.data;
      } else {
        // console.log("response", response.data.message);
        message.error(response.data.message);
      }
    } catch (error) {
      // console.log("callledddddddd22222 error");
      message.error(error.message);
      return null;
    }
  },
};

export default MsmeApi;
