import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, Descriptions, Image, Button, Modal, Spin, Space } from "antd";
import {
  FileOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileImageOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

export default function MsmeDetails() {
  const location = useLocation();
  const { msmeData } = location.state || {};
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Reset iframe when modal closes
    if (!previewVisible) {
      setPreviewUrl("");
    }
  }, [previewVisible]);

  if (!msmeData) {
    return <div>No MSME data available</div>;
  }

  console.log("msme Dataaa", msmeData);
  const getFileExtension = (url) => {
    return url.split(".").pop().toLowerCase();
  };
  const handlePreview = (fileUrl, title) => {
    setLoading(true);
    const extension = getFileExtension(fileUrl);
    if (extension === "pdf" || extension === "doc" || extension === "docx") {
      // Add a timestamp to prevent caching
      const timestamp = new Date().getTime();
      setPreviewUrl(
        `https://docs.google.com/gview?url=${encodeURIComponent(
          fileUrl
        )}&embedded=true&t=${timestamp}`
      );

      console.log("ppppp", fileUrl);
    } else {
      setPreviewUrl(fileUrl);
    }
    setPreviewTitle(title);
    setPreviewVisible(true);
  };

  const handleDownload = (fileUrl, fileName) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderFilePreview = (fileUrl, fileKey) => {
    const extension = getFileExtension(fileUrl);
    const title =
      fileKey === "screenshot"
        ? "Payment Screenshot"
        : fileKey.charAt(0).toUpperCase() +
          fileKey
            .slice(1)
            .replace(/([A-Z])/g, " $1")
            .trim();
    const fileName = `${title}.${extension}`;

    const downloadButton = (
      <Button
        icon={<DownloadOutlined />}
        onClick={() => handleDownload(fileUrl, fileName)}
      >
        Download
      </Button>
    );

    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return (
          <Space direction="vertical">
            <Image src={fileUrl} alt={fileKey} width={300} />
            {downloadButton}
          </Space>
        );
      case "pdf":
        return (
          <Space>
            <Button
              icon={<FilePdfOutlined />}
              onClick={() => handlePreview(fileUrl, title)}
            >
              View PDF
            </Button>
            {downloadButton}
          </Space>
        );
      case "doc":
      case "docx":
        return (
          <Space>
            <Button
              icon={<FileWordOutlined />}
              onClick={() => handlePreview(fileUrl, title)}
            >
              View Document
            </Button>
            {downloadButton}
          </Space>
        );
      default:
        return (
          <Space>
            <Button
              icon={<FileOutlined />}
              onClick={() => window.open(fileUrl, "_blank")}
            >
              View File
            </Button>
            {downloadButton}
          </Space>
        );
    }
  };

  const documentKeys = [
    "screenshot",
    "aadhaarCard",
    "trainingRegistrationForm",
    "udyamCertificate",
    "educationCertificate",
    "casteCertificate",
    "authorizationLetter",
  ];

  return (
    <div style={{ overflowY: "scroll", maxHeight: "100%" }}>
      <div className="mx-10 my-8">
        <h1 className="text-3xl font-bold mb-6">MSME Details</h1>
        <Card>
          <Descriptions title="Personal Information" bordered column={2}>
            <Descriptions.Item label="Name">{msmeData.name}</Descriptions.Item>
            <Descriptions.Item label="Email">
              {msmeData.email}
            </Descriptions.Item>
            <Descriptions.Item label="Mobile Number">
              {msmeData.mobileNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Gender">
              {msmeData.gender}
            </Descriptions.Item>
            <Descriptions.Item label="Organization">
              {msmeData.organizationName}
            </Descriptions.Item>
            <Descriptions.Item label="Designation">
              {msmeData.designation}
            </Descriptions.Item>
            <Descriptions.Item label="Qualification">
              {msmeData.education}
            </Descriptions.Item>
            <Descriptions.Item label="Category">
              {msmeData.category}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {msmeData.address}
            </Descriptions.Item>
            <Descriptions.Item label="Certificate Number">
              {msmeData.udyamNumber}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions title="Dates" bordered column={2} className="mt-6">
            <Descriptions.Item label="Created Date">
              {msmeData.createdDate}
            </Descriptions.Item>
            <Descriptions.Item label="Created Time">
              {msmeData.createdTime}
            </Descriptions.Item>
          </Descriptions>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Documents</h2>
          <div className="grid grid-cols-2 gap-6">
            {documentKeys.map((fileKey) => (
              <div key={fileKey} className="mb-4">
                <h3 className="text-lg font-medium mb-2">
                  {fileKey === "screenshot"
                    ? "Payment Screenshot"
                    : fileKey.charAt(0).toUpperCase() +
                      fileKey
                        .slice(1)
                        .replace(/([A-Z])/g, " $1")
                        .trim()}
                </h3>
                {msmeData[fileKey] ? (
                  renderFilePreview(msmeData[fileKey], fileKey)
                ) : (
                  <p>No file available</p>
                )}
              </div>
            ))}
          </div>
        </Card>
      </div>

      <Modal
        title={previewTitle}
        visible={previewVisible}
        onCancel={() => {
          setPreviewVisible(false);
          setLoading(true);
        }}
        footer={null}
        centered
        width="80%"
        bodyStyle={{ height: "80vh" }}
      >
        {loading && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Spin size="large" />
          </div>
        )}
        <iframe
          src={previewUrl}
          style={{
            width: "100%",
            height: "100%",
            display: loading ? "none" : "block",
          }}
          frameBorder="0"
          onLoad={() => setLoading(false)}
        ></iframe>
      </Modal>
    </div>
  );
}
