import React, { useState, useEffect } from "react";
import { Table, Button, Pagination } from "antd";
import { FaEye, FaFileExport } from "react-icons/fa";
import MsmeApi from "../services/api/msmeApi";
import { useNavigate } from "react-router-dom";

export default function Msme() {
  const [msmeList, setMsmeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [csvData, setCsvData] = useState([]);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    showQuickJumper: true,
  });

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) =>
        index + 1 + (pagination.current - 1) * pagination.pageSize,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "Organization",
      dataIndex: "organizationName",
      key: "organizationName",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex items-center">
          <FaEye
            className="text-xl text-green-500 cursor-pointer"
            onClick={() => handleViewMore(record)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchMsmeData(pagination.current, pagination.pageSize);
    fetchAllCsvData();
  }, []);

  const fetchAllCsvData = async (req, res) => {
    try {
      const response = await MsmeApi.findAllCsv({});
      console.log("alllllllllcsvvvv", response);
      setCsvData(response);
    } catch (error) {
      console.log("error while fetching allCsvData", error);
    }
  };

  const handleViewMore = (record) => {
    navigate("/msme-details", { state: { msmeData: record } });
  };

  const fetchMsmeData = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const response = await MsmeApi.findAll({ page, pageSize });
      if (response && response.data) {
        setMsmeList(response.data);
        setPagination((prev) => ({
          ...prev,
          current: response.currentPage || page,
          pageSize: response.pageSize || pageSize,
          total: response.total || prev.total,
        }));
      }
    } catch (error) {
      console.error("Error fetching MSME data:", error);
    } finally {
      setLoading(false);
    }
  };

  const exportToCSV = () => {
    if (csvData.length === 0) {
      console.log("No CSV data available to export");
      return;
    }

    const headers = Object.keys(csvData[0]).join(",");
    const csvContent = [
      headers,
      ...csvData.map((item) =>
        Object.values(item)
          .map((value) =>
            value === null ? "" : String(value).replace(/,/g, ";")
          )
          .join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "msme_full_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchMsmeData(newPagination.current, newPagination.pageSize);
  };

  return (
    <div style={{ overflowY: "scroll", maxHeight: "100%" }}>
      <div className="mx-10">
        <h1 className="text-3xl font-bold mt-4 uppercase">Msme</h1>
        <h2 className="text-xs font-medium italic text-gray-400">
          Manage the Msme data here
        </h2>

        <div className="flex justify-end mb-4">
          <Button
            type="primary"
            icon={<FaFileExport />}
            onClick={exportToCSV}
            className="bg-blue-500 hover:bg-blue-600 text-white"
          >
            Export to CSV
          </Button>
        </div>

        <div className="flex flex-col justify-center items-center">
          <Table
            dataSource={msmeList}
            columns={columns}
            pagination={false}
            onChange={handleTableChange}
            loading={loading}
            style={{ width: "100%", margin: "0 auto", marginTop: "20px" }}
          />
          <div className="mt-4 mb-4">
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              showSizeChanger
              showQuickJumper
              onChange={(page, pageSize) => fetchMsmeData(page, pageSize)}
              onShowSizeChange={(current, size) => fetchMsmeData(current, size)}
              style={{ textAlign: "right" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
