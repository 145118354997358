import { message } from "antd";
import axios from "axios";

const ContactApi = {
  create: async (body) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}contact/create`,
        body
      );

      if (response) {
        message.success("Our team will contact you shortly");
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.response.data.message);
      return null;
    }
  },

  findAll: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}contact/findAll`,
        { params }
      );

      if (response.data.status) {
        console.log("response", response);
        return response.data;
      } else {
        console.log("response", response.data.message);
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },
};

export default ContactApi;
