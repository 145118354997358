import moment from "moment-timezone";
import momentTimezone from "moment-timezone";

const getCurrentDateTimeMoment = () => {
  return momentTimezone().tz("Asia/Kolkata");
};

const DateUtils = {
  getDateYyyyMmDd: (date) => {
    return momentTimezone(date).tz("Asia/Kolkata").format("YYYY-MM-DD");
  },
  getCurrentDate: () => {
    return getCurrentDateTimeMoment().format("YYYY-MM-DD");
  },
  getCurrentDateFormat: () => {
    return getCurrentDateTimeMoment().format("DD-MM-YYYY");
  },
  getExDateFormat: (date) => {
    return momentTimezone(date).tz("Asia/Kolkata").format("DD-MM-YYYY");
  },
  getCurrentDateTime: () => {
    return getCurrentDateTimeMoment().format("YYYY-MM-DD HH:mm:ss");
  },
  getTMinusDate: (days) => {
    return getCurrentDateTimeMoment()
      .subtract(days, "days")
      .format("YYYY-MM-DD");
  },
  getTPlusDate: (days) => {
    return getCurrentDateTimeMoment().add(days, "days").format("YYYY-MM-DD");
  },
  getTMinusDateTime: (days) => {
    return getCurrentDateTimeMoment()
      .subtract(days, "days")
      .format("YYYY-MM-DD HH:mm:ss");
  },
  getTPlusDateTime: (days) => {
    return getCurrentDateTimeMoment()
      .add(days, "days")
      .format("YYYY-MM-DD HH:mm:ss");
  },
  convertDate: (date) => {
    return momentTimezone(date).tz("Asia/Kolkata").format("DD-MM-YYYY");
  },
  convertDateReverse: (date) => {
    return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
  },
  convertTime: (time) => {
    return momentTimezone(time).tz("Asia/Kolkata").format("HH:mm");
  },
  getCurrentTime: () => {
    return moment().tz("Asia/Kolkata").format("HH:mm");
  },
  getAddedMonth: (date) => {
    return moment(date).add(7, "days").format("MM");
  },
  getDifferenceDate: (dateFrom, DateTo) => {
    return moment(dateFrom).diff(DateTo, "days");
  },
  convertUnixTimestamp: (timestamp) => {
    return moment.unix(timestamp).tz("Asia/Kolkata").format("DD-MM-YY / HH:mm");
  },
};
export default DateUtils;
