import { message } from "antd";
import axios from "axios";

const UrlApi = {
  findAll: async ({ params }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}contact/getAllUrlInfo`,
        { params }
      );

      if (response.data.status) {
        console.log("response", response);
        return response.data.data;
      } else {
        console.log("response", response.data.message);
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },
};

export default UrlApi;
