import { message } from "antd";
import axios from "axios";
const ENCRYPTION_KEY = "EgniolSecretKey";
const TOKEN_EXPIRATION_TIME = 24 * 60 * 60 * 1000;

const encryptDecrypt = (text, key) => {
  let result = "";
  for (let i = 0; i < text.length; i++) {
    result += String.fromCharCode(
      text.charCodeAt(i) ^ key.charCodeAt(i % key.length)
    );
  }
  return result;
};

const LoginVerify = {
  signin: async (email, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/login`,
        { email, password }
      );

      if (response.data.status) {
        const { id, name, email, token, role } = response.data.data;
        const expirationTime = new Date().getTime() + TOKEN_EXPIRATION_TIME;

        localStorage.setItem("accessTokenBlog", token);
        localStorage.setItem("emailBlog", email);
        localStorage.setItem("nameBlog", name);
        localStorage.setItem("idBlog", id);
        localStorage.setItem("roleBlog", encryptDecrypt(role, ENCRYPTION_KEY));
        localStorage.setItem("tokenExpirationBlog", expirationTime.toString());

        message.success("Successfully logged in.");
        return response.data.data;
      } else {
        message.error(response.data.message);
        return null;
      }
    } catch (error) {
      console.error("Error during login", error);
      message.error("Error during login");
      return null;
    }
  },
  logout: () => {
    localStorage.removeItem("accessTokenBlog");
    localStorage.removeItem("emailBlog");
    localStorage.removeItem("nameBlog");
    localStorage.removeItem("idBlog");
    localStorage.removeItem("roleBlog");
    localStorage.removeItem("tokenExpirationBlog");
  },
  getCurrentUser: () => {
    const encryptedRole = localStorage.getItem("roleBlog");
    const tokenExpiration = localStorage.getItem("tokenExpirationBlog");
    if (tokenExpiration && new Date().getTime() > parseInt(tokenExpiration)) {
      LoginVerify.logout();
      message.info("Your session has expired. Please log in again.");
      return null;
    }
    return {
      id: localStorage.getItem("idBlog"),
      name: localStorage.getItem("nameBlog"),
      email: localStorage.getItem("emailBlog"),
      token: localStorage.getItem("accessTokenBlog"),
      role: encryptedRole
        ? encryptDecrypt(encryptedRole, ENCRYPTION_KEY)
        : null,
    };
  },
  isAuthenticated: () => {
    const tokenExpiration = localStorage.getItem("tokenExpirationBlog");
    if (tokenExpiration && new Date().getTime() > parseInt(tokenExpiration)) {
      LoginVerify.logout();
      return false;
    }
    return !!localStorage.getItem("accessTokenBlog");
  },

  findAll: async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}admin/findAll`
      );
      if (response.data.status) {
        console.log("response", response);
        return response.data.data;
      } else {
        console.log("response", response.data.message);
        message.error(response.data.message);
      }
    } catch (error) {
      console.log("callledddddddd22222 error");
      message.error(error.message);
      return null;
    }
  },
  create: async (body) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/signup`,
        body
      );

      if (response) {
        // message.success(response.data.message);
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.response.data.message);
      return null;
    }
  },
  update: async (id, body) => {
    console.log("iddddddd=-", id, body);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}admin/update/${id}`,
        body
      );

      if (response.data.status) {
        console.log("response", response);
        // message.success(response.data.message);
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },
  delete: async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}admin/delete/${id}`
      );

      if (response.data.status) {
        console.log("response", response);
        // message.success(response.data.message);
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },
};

export default LoginVerify;
