import React, { useState, useEffect } from "react";
import TextEditor from "../components/blogs/TextEditor";
import { Table, Button, Modal, Input, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Upload from "../components/reuse/Upload";
import UploadApi from "../services/api/uploadApi";
import { FaRegEdit, FaEye, FaRegCopy } from "react-icons/fa";

import { MdDelete } from "react-icons/md";

export default function UploadImg() {
  const [uploadList, setUploadList] = useState([]);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "File URL",
      dataIndex: "description",
      key: "description",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex items-center space-x-2">
          <FaRegCopy
            className="text-xl text-blue-500 cursor-pointer"
            onClick={() => handleCopy(record)}
          />

          <MdDelete
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => handleDelete(record)}
          />
        </div>
      ),
    },
  ];

  const handleCopy = (record) => {
    console.log("copy", record);
    navigator.clipboard.writeText(record.description);
    message.success("Copied to clipboard");
  };

  const handleDelete = (record) => {
    console.log("Delete", record);
    const id = record.id;
    Modal.confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this image?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        const response = await UploadApi.delete(id);
        fetchUploadList();
      },
    });
  };

  useEffect(() => {
    fetchUploadList();
  }, []);

  const fetchUploadList = async () => {
    const response = await UploadApi.findAll({});
    if (response) {
      console.log("response", response);
      setUploadList(response);
    }
  };

  return (
    <div className=" mx-10">
      <h1 className="text-3xl font-bold mt-4 uppercase">
        Upload Image
      </h1>
      <h2 className="text-xs font-medium italic text-gray-400">
        Manage the upload images here
      </h2>
      <div className="flex justify-end">
        <Upload fetchUploadList={fetchUploadList} />
      </div>
      <div className="flex justify-center items-center">
        <Table
          dataSource={uploadList}
          columns={columns}
          style={{ width: "100%", margin: "0 auto", marginTop: "20px" }}
        />
      </div>
    </div>
  );
}
