import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Input, Select, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import LoginVerify from "../services/api/loginApi";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const { Option } = Select;

const AdminManagement = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      const response = await LoginVerify.findAll({});
      if (response) {
        setAllUsers(response);
      }
    } catch (error) {
      console.log("Error fetching users", error);
      message.error("Failed to fetch users");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <span className="flex  items-center gap-5">
          <FaRegEdit
            className="text-xl text-blue-500 cursor-pointer"
            onClick={() => handleEdit(record)}
          />

          <MdDelete
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => handleDelete(record.id)}
          />
        </span>
      ),
    },
  ];

  const handleEdit = (record) => {
    form.setFieldsValue(record);
    setSelectedId(record.id);
    setIsModalVisible(true);
    setIsEdit(true);
  };

  const handleDelete = async (id) => {
    try {
      Modal.confirm({
        title: "Confirm Delete",
        content: "Are you sure you want to delete this User Data?",
        okText: "Delete",
        okType: "danger",
        cancelText: "Cancel",
        onOk: async () => {
          const response = await LoginVerify.delete(id);
          getAllUsers();
        },
      });
    } catch (error) {
      console.error(error && error.message);
    }
  };

  const handleAddUser = () => {
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    form.validateFields().then(async (values) => {
      try {
        if (isEdit) {
          console.log("came in edittt", values);
          const response = await LoginVerify.update(selectedId, values);

          message.success("User added successfully");
          setIsModalVisible(false);
          form.resetFields();
          setIsEdit(false);
          setSelectedId(null);
          getAllUsers();
        } else {
          console.log("came in adddd", values);
          const response = await LoginVerify.create(values);

          message.success("User added successfully");
          setIsModalVisible(false);
          form.resetFields();
          setIsEdit(false);
          setSelectedId(null);
          getAllUsers();
        }
      } catch (error) {
        message.error("Failed to add user");
      }
    });
  };

  return (
    <div
      style={{ overflowY: "scroll", maxHeight: "100%" }}
      className="m-5 pr-5"
    >
      <div className="w-full flex justify-end">
        <Button
          type="primary"
          onClick={handleAddUser}
          style={{ marginBottom: 16 }}
        >
          Add User
        </Button>
      </div>

      <Table columns={columns} dataSource={allUsers} rowKey="id" />
      <Modal
        title="Add/Edit User"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={() => {
          setIsModalVisible(false);
          setSelectedId(null);
          form.resetFields();
          setIsEdit(false);
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="password" label="Password">
            <Input.Password />
          </Form.Item>
          <Form.Item name="role" label="Role" rules={[{ required: true }]}>
            <Select>
              <Option value="admin">Admin</Option>
              <Option value="seo_manager">SEO Manager</Option>
              <Option value="blogger">Blogger</Option>
              <Option value="leads_manager">Leads Manager</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AdminManagement;
