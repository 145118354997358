import { message } from "antd";
import axios from "axios";

const ServiceApi = {
  create: async (body) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}service/create`,
        body
      );

      if (response) {
        message.success(response.data.message);
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.response.data.message);
      return null;
    }
  },

  findAll: async ({ params }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}service/findAll`
      );

      if (response.data.status) {
        // console.log("response", response);
        return response.data.data;
      } else {
        // console.log("response", response.data.message);
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },

  findOne: async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}category/findOne/${id}`
      );

      if (response.data.status) {
        console.log("response", response);
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },

  update: async (id, body) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}service/update/${id}`,
        body
      );

      if (response.data.status) {
        console.log("response", response);
        message.success(response.data.message);
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },

  delete: async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}service/delete/${id}`
      );

      if (response.data.status) {
        console.log("response", response);
        message.success(response.data.message);
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },
};

export default ServiceApi;
