import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../navbar/Navbar";

export default function NavbarLayout({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const url = location.pathname;

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const isMobileScreen = window.innerWidth <= 768;
    if (isMobileScreen) {
      setIsOpen(false);
    }
  }, [location]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessTokenBlog");
    if (!accessToken) {
      navigate("/login");
    } else {
      if (url === "/") {
        navigate("/home");
      }
    }
  }, []);

  return (
    <Navbar isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="h-screen overflow-hidden w-full">
        <Outlet />
      </div>
    </Navbar>
  );
}
