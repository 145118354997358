import React, { useEffect, useState } from "react";
import { Table, Button, Modal } from "antd";
import BlogApi from "../services/api/blogApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Space, Switch } from "antd";
import DateUtils from "../services/utils/dateUtils";

export default function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [isPublished, setIsPublished] = useState(false);
  const [record, setRecord] = useState({});

  const navigate = useNavigate();

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Featured Image",
      dataIndex: "featureImage",
      key: "featureImage",
      render: (featureImage) => (
        <a href={featureImage} target="_blank" rel="noopener noreferrer">
          <img
            src={featureImage}
            alt="Layout Image"
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
        </a>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category) => category ? category.name : "-",
    },
    {
      title: "Published",
      dataIndex: "date",
      key: "date",
      render: (date, record) => (
        <div className="flex flex-col justify-start items-start">
          <Space direction="vertical" colorPrimary="#fff">
            <Switch
              checkedChildren="Published"
              unCheckedChildren="Pending"
              defaultChecked
              checked={record.isPublished}
              onChange={() => handleTogglePublish(record)}
            />
          </Space>
          <p>
            {record.publishedAtUnix
              ? DateUtils.convertUnixTimestamp(record.publishedAtUnix)
              : ""}
          </p>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex items-center space-x-2">
          <FaRegEdit
            className="text-xl text-blue-500 cursor-pointer"
            onClick={() => handleEdit(record)}
          />
          <FaEye
            className="text-xl text-green-500 cursor-pointer"
            onClick={() => handleView(record)}
          />
          <MdDelete
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => handleDelete(record)}
          />
        </div>
      ),
    },
  ];

  const handleTogglePublish = (record) => {
    console.log("record", record);
    setRecord(record);
    if (record.isPublished === false) {
      Modal.confirm({
        title: "Confirm Publish",
        content: "Are you sure you want to publish this blog?",
        okText: "Publish",
        okType: "primary",
        cancelText: "Cancel",
        onOk: async () => {
          await BlogApi.updateIsPublished(record.id, { isPublished: true });
          fetchBlogs();
        },
      });
    }
    if (record.isPublished === true) {
      Modal.confirm({
        title: "Confirm Unpublish",
        content: "Are you sure you want to unpublish this blog?",
        okText: "Unpublish",
        okType: "primary",
        cancelText: "Cancel",
        onOk: async () => {
          await BlogApi.updateIsPublished(record.id, { isPublished: false });
          fetchBlogs();
        },
      });
    }
  };

  const handleEdit = (record) => {
    console.log("Edit", record);
    navigate(`/blogs/edit-blog?id=${record.id}`);
  };

  const handleView = (record) => {
    console.log("View", record);
    navigate(`/blogs/view-blog?id=${record.id}`);
  };

  const handleDelete = async (record) => {
    console.log("Delete", record);
    try {
      Modal.confirm({
        title: "Confirm Delete",
        content: "Are you sure you want to delete this blog?",
        okText: "Delete",
        okType: "danger",
        cancelText: "Cancel",
        onOk: async () => {
          const response = await BlogApi.delete(record.id);
          fetchBlogs();
        },
      });
    } catch (error) {
      console.error(error && error.message);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await BlogApi.findAll({});
      console.log("response123", response);
      setBlogs(response);
      setIsPublished(response.isPublished);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ overflowY: "scroll", maxHeight: "600px" }}>
      <div className=" mx-10">
        <h1 className="text-3xl font-bold mt-4 uppercase">Blogs</h1>
        <h2 className="text-xs font-medium italic text-gray-400">
          Manage the blogs here
        </h2>
        <div className="flex justify-end">
          <Link to="/add-blog">
            <Button type="primary">Add Blog</Button>
          </Link>
        </div>
        <div className="flex justify-center items-center">
          <Table
            dataSource={blogs}
            columns={columns}
            pagination={false}
            style={{ width: "100%", margin: "0 auto", marginTop: "20px" }}
          />
        </div>
      </div>
    </div>
  );
}
