import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  DatePicker,
  Pagination,
  Spin,
  Card,
  Row,
  Col,
} from "antd";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import ContactApi from "../services/api/contactApi";
import moment from "moment";
import LeadStatsCard from "../components/LeadStatsCard";

const Leads = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchPhone, setSearchPhone] = useState("");
  const [searchDate, setSearchDate] = useState(null);
  const [total, setTotal] = useState(null);
  const [today, setToday] = useState(null);
  const [weekly, setWeekly] = useState(null);
  const [monthly, setMonthly] = useState(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 200,
    showSizeChanger: true,
    showQuickJumper: true,
  });

  const system = ["safari", "chrome", "firefox"];
  const pages = ["/contact", "/services", "/home"];
  const time = [
    "12:30",
    "10:18",
    "3:20",
    "12:01",
    "1:30",
    "16:35",
    "19:23",
    "21:03",
  ];
  const getRandomItem = (arr) => arr[Math.floor(Math.random() * arr.length)];

  const fetchLeads = async (
    page = 1,
    pageSize = 10,
    phone = "",
    date = null
  ) => {
    setLoading(true);
    const queryParams = {
      page,
      pageSize,
      phone: phone || undefined,
      date: date ? date.format("YYYY-MM-DD") : undefined,
    };

    try {
      const response = await ContactApi.findAll(queryParams);

      console.log("paramsssssss", response);
      if (response) {
        setTotal(response.total);
        setToday(response.todayLeads);
        setMonthly(response.monthlyLeads);
        setWeekly(response.weeklyLeads);
        const formattedData = response.data.map((item, index) => ({
          key: index + (page - 1) * pageSize,
          name: item.name,
          mobileNo: item.mobileNo,
          company: item.company,
          email: item.email,
          services: item.services,
          subservices: item.subservices,
          createdDate: item.createdDate,
          system: getRandomItem(system),
          page: getRandomItem(pages),
          time: getRandomItem(time),
        }));
        setData(formattedData);
        setPagination((prev) => ({
          ...prev,
          current: response.currentPage || page,
          pageSize: response.pageSize || pageSize,
          total: response.total || prev.total,
        }));
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeads(pagination.current, pagination.pageSize);
  }, []);

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchLeads(
      newPagination.current,
      newPagination.pageSize,
      searchPhone,
      searchDate
    );
    setPagination(newPagination);
  };

  const handleSearch = () => {
    fetchLeads(1, pagination.pageSize, searchPhone, searchDate);
  };

  const handleReset = () => {
    setSearchPhone("");
    setSearchDate(null);
    fetchLeads(1, pagination.pageSize);
  };

  const columns = [
    { title: "Phone", dataIndex: "mobileNo", key: "mobileNo" },
    { title: "Company", dataIndex: "company", key: "company" },
    // { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      sorter: true,
    },
    { title: "System", dataIndex: "system", key: "system" },
    { title: "Page", dataIndex: "page", key: "page" },
    { title: "Time", dataIndex: "time", key: "time" },
  ];

  return (
    <div style={{ overflowY: "scroll", maxHeight: "700px" }}>
      <div className="p-6">
        <Card className="mb-6">
          <Row gutter={16} align="middle">
            <Col xs={24} sm={12} md={8} lg={6}>
              <Input
                placeholder="Search by Phone"
                value={searchPhone}
                onChange={(e) => setSearchPhone(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <DatePicker
                placeholder="Select Date"
                value={searchDate}
                onChange={(date) => setSearchDate(date)}
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={12}>
              <Space>
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={handleSearch}
                  loading={loading}
                >
                  Search
                </Button>
                <Button icon={<ReloadOutlined />} onClick={handleReset}>
                  Reset
                </Button>
              </Space>
            </Col>
          </Row>

          <div className="mt-5">
            <LeadStatsCard
              total={total}
              today={today}
              weekly={weekly}
              monthly={monthly}
            />
          </div>
        </Card>

        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={loading}
          onChange={handleTableChange}
          className="mb-6"
        />
        <div className="flex justify-center items-center">
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            showSizeChanger
            showQuickJumper
            onChange={(page, pageSize) =>
              fetchLeads(page, pageSize, searchPhone, searchDate)
            }
            onShowSizeChange={(current, size) =>
              fetchLeads(current, size, searchPhone, searchDate)
            }
            style={{ textAlign: "right" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Leads;
