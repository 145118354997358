import React, { useState, useEffect } from "react";
import { Input, Button } from "antd";
import LoginVerify from "../services/api/loginApi";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/Egniol_web_Logo-2.webp";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleLogin = async () => {
    const response = await LoginVerify.signin(email, password);
    if (response) {
      navigate("/home");
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-[#233240]">
      <div className="w-[400px] p-6 bg-white rounded shadow">
        <div className="flex justify-center mb-6">
          <img src={Logo} alt="Logo" className="h-10" />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Username</label>
          <Input
            placeholder="Enter your username"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Password</label>
          <Input.Password
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="text-center">
          <Button type="primary" onClick={handleLogin}>
            Login
          </Button>
        </div>
      </div>
    </div>
  );
}
