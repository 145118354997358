import React from "react";
import { Select } from "antd";

const MultiTags = ({ setTags, tags, tagList, errors, disabled }) => {
  const options = tagList.map((tag) => ({
    value: tag.name,
    label: tag.name,
  }));

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setTags(value);
  };

  return (
    <>
      <Select
        mode="tags"
        // style={{
        //   width: "100%",
        // }}
        placeholder="Tags Mode"
        onChange={handleChange}
        value={tags}
        options={options}
        disabled={disabled}
      />
      {errors && (
        <p className="text-red-500 text-sm">{errors}</p>
      )}
    </>
  );
};

export default MultiTags;
