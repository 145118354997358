import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Select } from "antd";
import BlogApi from "../services/api/blogApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Space, Switch } from "antd";
import DateUtils from "../services/utils/dateUtils";
import SeoApi from "../services/api/seoApi";
import ServiceTypeApi from "../services/api/serviceTypeApi";
const { Option } = Select;

export default function Seo() {
  const [seoData, setSeoData] = useState([]);
  const [originalSeoData, setOriginalSeoData] = useState([]);
  const [services, setServices] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [isPublished, setIsPublished] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [record, setRecord] = useState({});

  const navigate = useNavigate();

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Meta Title",
      dataIndex: "metaTitle",
      key: "metaTitle",
    },
    {
      title: "PermaLinks",
      dataIndex: "Permalinks",
      key: "Permalinks",
    },
    {
      title: "Meta Description",
      dataIndex: "metaDescription",
      key: "metaDescription",
    },
    // {
    //   title: "Meta Schema",
    //   dataIndex: "metaSchema",
    //   key: "metaSchema",
    // },
    {
      title: "Meta Tags",
      dataIndex: "metaTags",
      key: "metaTags",
      render: (tags) => (
        <>
          {Array.isArray(tags) ? (
            tags.map((tag, index) => (
              <span key={index} className="tag">
                {tag}
                {index < tags.length - 1 ? ", " : ""}
              </span>
            ))
          ) : (
            <span>No tags</span>
          )}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex items-center space-x-2">
          <FaRegEdit
            className="text-xl text-blue-500 cursor-pointer"
            onClick={() => handleEdit(record)}
          />
          <FaEye
            className="text-xl text-green-500 cursor-pointer"
            onClick={() => handleView(record)}
          />
          <MdDelete
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => handleDelete(record)}
          />
        </div>
      ),
    },
  ];

  const handleEdit = (record) => {
    console.log("Edit", record);
    navigate(`/seo/edit-seo?id=${record.id}`);
  };

  const handleView = (record) => {
    console.log("View", record);
    navigate(`/seo/view-seo?id=${record.id}`);
  };

  const handleDelete = async (record) => {
    console.log("Delete", record);
    try {
      Modal.confirm({
        title: "Confirm Delete",
        content: "Are you sure you want to delete this Seo Data?",
        okText: "Delete",
        okType: "danger",
        cancelText: "Cancel",
        onOk: async () => {
          const response = await SeoApi.delete(record.id);
          fetchBlogs();
        },
      });
    } catch (error) {
      console.error(error && error.message);
    }
  };

  useEffect(() => {
    fetchBlogs();
    fetchServices();
    fetchFilterBlogs();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await ServiceTypeApi.findAll({});

      if (response) {
        console.log("response serviceType", response);
        setServices(response);
      }
    } catch (error) {
      console.log("Service Type Filter Error", error);
    }
  };

  const fetchFilterBlogs = async () => {
    try {
      try {
        const response = await BlogApi.findAll({});
        setBlogs(response);
        if (response) {
          console.log("response Blog", response);
        }
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.log("Blog Filter Error", error);
    }
  };

  const fetchBlogs = async () => {
    try {
      const response = await SeoApi.findAll();
      // console.log("response123", response);
      const formattedResponse = response.map((seo) => ({
        ...seo,
        metaTags: Array.isArray(seo.metaTags)
          ? seo.metaTags
          : seo.metaTags
          ? seo.metaTags.split(",")
          : [],
      }));
      setSeoData(formattedResponse);
      setOriginalSeoData(formattedResponse);
      // setIsPublished(response.isPublished);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    filterSeoData();
  }, [selectedService, selectedBlog]);
  const filterSeoData = () => {
    let filteredData = originalSeoData;

    if (selectedService) {
      const serviceSlug = slugify(selectedService);
      filteredData = filteredData.filter((item) => {
        const permalinkParts = item.Permalinks.split("/");
        const lastPart = permalinkParts[permalinkParts.length - 1];
        return lastPart === serviceSlug;
      });
    }

    if (selectedBlog) {
      const blogSlug = slugify(selectedBlog);
      filteredData = filteredData.filter((item) => {
        const permalinkParts = item.Permalinks.split("/");
        const lastPart = permalinkParts[permalinkParts.length - 1];
        return lastPart === blogSlug;
      });
    }

    setSeoData(filteredData);
  };
  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  };
  const handleServiceChange = (value) => {
    if (selectedBlog) {
      setSelectedBlog(null);
    }
    setSelectedService(value);
  };

  const handleBlogChange = (value) => {
    if (selectedService) {
      setSelectedService(null);
    }
    setSelectedBlog(value);
  };

  return (
    <div style={{ overflowY: "scroll", maxHeight: "100%" }}>
      <div className="mx-10">
        <h1 className="text-3xl font-bold mt-4 uppercase">Seo</h1>
        <h2 className="text-xs font-medium italic text-gray-400">
          Manage the seo here
        </h2>
        <div className="flex justify-between items-center mt-4">
          <div className="flex space-x-4">
            <Select
              style={{ width: 200 }}
              placeholder="Filter by Service"
              onChange={handleServiceChange}
              value={selectedService}
            >
              {services.map((service) => (
                <Option key={service.id} value={service.title}>
                  {service.title}
                </Option>
              ))}
            </Select>
            <Select
              style={{ width: 200 }}
              placeholder="Filter by Blog"
              onChange={handleBlogChange}
              value={selectedBlog}
            >
              {blogs.map((blog) => (
                <Option key={blog.id} value={blog.title}>
                  {blog.title}
                </Option>
              ))}
            </Select>
            <Button
              onClick={() => {
                fetchBlogs();
                setSelectedBlog(null);
                setSelectedService(null);
              }}
              type="primary"
            >
              Reset Filter
            </Button>
          </div>
          <Link to="/add-seo">
            <Button type="primary">Add Seo</Button>
          </Link>
        </div>
        <div className="flex justify-center items-center">
          <Table
            dataSource={seoData}
            columns={columns}
            pagination={false}
            style={{ width: "100%", margin: "0 auto", marginTop: "20px" }}
          />
        </div>
      </div>
    </div>
  );
}
