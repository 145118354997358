import { message } from "antd";
import axios from "axios";

const SeoApi = {
  create: async (body) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}seo/create`,
        body
      );

      if (response) {
        message.success(response.data.message);
        return response.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.response.data.message);
      return null;
    }
  },

  findAll: async () => {
    console.log("callledddddddd");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}seo/findAll`
      );
      console.log("callledddddddd22222");
      console.log("[[][][][]", response);
      if (response.data.status) {
        console.log("response", response);
        return response.data.data;
      } else {
        console.log("response", response.data.message);
        message.error(response.data.message);
      }
    } catch (error) {
      console.log("callledddddddd22222 error");
      message.error(error.message);
      return null;
    }
  },
  findOne: async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}seo/findOne/${id}`
      );

      if (response.data.status) {
        console.log("response", response);
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },
  findByLink: async (body) => {
    try {
      //   console.log("linkkki------", body);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}seo/findByLink`,
        body
      );

      if (response.data.status) {
        // console.log("response", response);
        return response.data.data;
      } else {
        // message.error(response.data.message);
        console.error(response.data.message);
      }
    } catch (error) {
      //   message.error(error.message);
      console.error("error in seo", error);
      return null;
    }
  },

  update: async (id, body) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}seo/update/${id}`,
        body
      );

      if (response.data.status) {
        console.log("response", response);
        message.success(response.data.message);
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },

  delete: async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}seo/delete/${id}`
      );

      if (response.data.status) {
        console.log("response", response);
        message.success(response.data.message);
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },
};

export default SeoApi;
