import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import LoginVerify from "../services/api/loginApi";

const ProtectedRoute = ({ allowedRoles }) => {
  const user = LoginVerify.getCurrentUser();

  if (!LoginVerify.isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }

  if (allowedRoles && !allowedRoles.includes(user.role)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
